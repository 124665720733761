import React from 'react'

import { Shortcut } from '../../../../components/ui/Shortcut'

import type { Shortcuts as ShortcutsProps } from '../../../../types/ClubCard/ClubCardUser'

import './shortcuts.scss'

const LIMIT_SHORTCUTS = 6

export const Shortcuts = ({ visibility, list, location }: ShortcutsProps) => {
  if (!visibility || !list?.length) {
    return null
  }

  const listShortcuts = list.slice(0, LIMIT_SHORTCUTS)

  return (
    <div
      className={`club-card-user-shortcuts shortcuts-${listShortcuts.length}`}
    >
      {listShortcuts?.map((itemShortcut, index) => (
        <Shortcut
          key={`${itemShortcut.label}-${index}`}
          text={itemShortcut.label}
          icon={itemShortcut.icon}
          href={itemShortcut.href}
          location={location}
        />
      ))}
    </div>
  )
}
