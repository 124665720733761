import React from 'react'

import { showSection } from '../../../../utils/showSection'
import { ClubProductsSection } from '../../Club/ClubProducts'

import type { showSectionVariables } from '../../../../utils/showSection'

import './campaign-club-products.scss'

interface Props {
  isActive: boolean
  title: string
  description: string
  visibilityConfig: showSectionVariables
}

export const CampaignClubProducts = (props: Props) => {
  const { title, description, isActive, visibilityConfig } = props

  if (!showSection(visibilityConfig) || isActive) return

  return (
    <div className="campaign-club-products-shelf">
      <ClubProductsSection
        title={title}
        description={description}
        location="Ofertas +amigo"
      />
    </div>
  )
}
