import '../styles/pages/club-card-benefits-page.scss'

import React from 'react'

import { Benefits } from '../components/ClubCard/Benefits/Benefits'
import { BenefitsDesktop } from '../components/ClubCard/Benefits/BenefitsDesktop'
import { Discounts } from '../components/ClubCard/Benefits/Discounts'
import { FAQ } from '../components/ClubCard/Benefits/Faq'
import { Points } from '../components/ClubCard/Benefits/Points'
import { Banner } from '../components/sections/Banner'

import type { ClubCardBenefitsPageSectionData } from '../types/ClubCard/ClubCardBenefits'

interface ClubCardBenefitsPageProps {
  data: ClubCardBenefitsPageSectionData
}

export function ClubCardBenefitsPage({ data }: ClubCardBenefitsPageProps) {
  const { mainBanner, link, legalText, faq, benefits, discounts, points } = data

  return (
    <div className="club-card-benefits-page-container">
      <Banner
        className="club-card-benefits-mainBanner"
        data={{ ...mainBanner, href: link }}
      />

      <Benefits benefits={benefits} link={link} />
      <BenefitsDesktop {...benefits} />
      <Discounts {...discounts} />
      <Points {...points} />

      <FAQ
        title={faq?.title}
        visibility={faq?.visibility}
        questions={faq?.questions}
      />

      {legalText.visibility && (
        <p className="club-card-benefits-legalText grid-content">
          {legalText.text}
        </p>
      )}
    </div>
  )
}
