interface Params {
  skuSpecifications: SKUSpecification[] | null
  hasClubWithRegion?: boolean
  currentSellerId?: string
}

type SKUSpecification = {
  field: { originalName: string }
  values: { originalName: string }[]
}

export function verifyProductClub({
  skuSpecifications,
  hasClubWithRegion = false,
  currentSellerId = '',
}: Params) {
  if (!skuSpecifications || skuSpecifications.length === 0) return false

  const clubPriceSpecification = skuSpecifications.find(
    (specification) => specification?.field?.originalName === 'Preco2'
  )?.values?.[0]?.originalName

  if (clubPriceSpecification?.toLowerCase() !== 'true') {
    return false
  }

  if (!hasClubWithRegion) {
    return true
  }

  const sellersSpecification = skuSpecifications?.find(
    (specification) => specification?.field?.originalName === 'LojasClube'
  )?.values?.[0]?.originalName

  const sellersStringList = sellersSpecification?.slice(2, -2)
  const validSellers = sellersStringList ? sellersStringList.split(',') : []

  return validSellers.includes(currentSellerId)
}
