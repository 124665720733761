import { Link } from 'gatsby'
import React from 'react'

import './button-points.scss'

interface ButtonPointsProps {
  href?: string
  text?: string
  className?: string
}

export const ButtonPoints = ({
  text,
  href,
  className = '',
}: ButtonPointsProps) => {
  if (!text || !href) {
    return null
  }

  return (
    <Link className={`buttonPoints-infos-button ${className}`} to={href}>
      {text}
    </Link>
  )
}
