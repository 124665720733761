import { gql } from '@faststore/graphql-utils'
import { createCartStore } from '@faststore/sdk'
import axios from 'axios'
import { useMemo } from 'react'

import { formatListPrice } from '../../utils/formatPrices'
import { request } from '../graphql/request'
import { sessionStore } from '../session'
import { createValidationStore, useStore } from '../useStore'

import type { CartItemFull } from '../../types/Product'
import type { Cart as SDKCart, CartItem as SDKCartItem } from '@faststore/sdk'
import type {
  CartMessageFragment,
  IStoreOffer,
  ValidateCartMutationMutation,
  ValidateCartMutationMutationVariables,
} from '@generated/graphql'

export interface CartItem extends SDKCartItem, CartItemFull {}

export interface UseCartReturn extends Cart {
  isValidating: boolean
  messages: CartMessageFragment[] | undefined
  gifts: CartItem[]
  items: CartItem[]
  totalUniqueItems: number
  totalItems: number
  total: number
  subTotal: number
}

export interface Cart extends SDKCart<CartItem> {
  messages?: CartMessageFragment[]
}

type AdditionalProperty = {
  propertyID: string
  name: string
  value: string
  valueReference: string
}

export const ValidateCartMutation = gql`
  mutation ValidateCartMutation($cart: IStoreCart!, $session: IStoreSession!) {
    validateCart(cart: $cart, session: $session) {
      order {
        orderNumber
        acceptedOffer {
          ...CartItem
        }
      }
      messages {
        ...CartMessage
      }
    }
  }

  # fragment CartMessage on StoreCartMessage {
  #   text
  #   status
  # }

  # fragment CartItem on StoreOffer {
  #   seller {
  #     identifier
  #   }
  #   quantity
  #   price
  #   listPrice
  #   itemOffered {
  #     ...CartProductItem
  #   }
  # }

  # fragment CartProductItem on StoreProduct {
  #   ...AdditionalProductInfos
  #   sku
  #   name
  #   image {
  #     url
  #     alternateName
  #   }
  #   brand {
  #     name
  #   }
  #   isVariantOf {
  #     productGroupID
  #     name
  #     additionalProperty {
  #       propertyID
  #       name
  #       value
  #       valueReference
  #     }
  #   }
  #   gtin
  #   additionalProperty {
  #     propertyID
  #     name
  #     value
  #     valueReference
  #   }
  # }
`

const isGift = (item: CartItem) => item.price === 0

export const getItemId = (
  item: Pick<CartItem, 'itemOffered' | 'seller' | 'price'>
) =>
  [
    item.itemOffered.sku,
    item.seller.identifier,
    item.itemOffered.additionalProperty
      ?.map(({ propertyID }: AdditionalProperty) => propertyID)
      .join('-'),
  ]
    .filter(Boolean)
    .join('::')

const [validationStore, onValidate] = createValidationStore(validateCart)
const defaultCartStore = createCartStore<Cart>(
  {
    id: '',
    items: [],
    messages: [],
  },
  onValidate
)

async function validateCart(cart: Cart): Promise<Cart | null> {
  let orderFormId = ''

  if (!cart?.id) {
    const { data } = await axios.post('/api/getCart')

    orderFormId = data?.orderFormId
  }

  const id = cart?.id ? cart.id : orderFormId

  const { validateCart: validated = null } = await request<
    ValidateCartMutationMutation,
    ValidateCartMutationMutationVariables
  >(ValidateCartMutation, {
    session: sessionStore.read(),
    cart: {
      order: {
        orderNumber: id,
        acceptedOffer: cart.items.map(
          ({
            price,
            listPrice,
            seller,
            quantity,
            itemOffered,
          }): IStoreOffer => ({
            price,
            listPrice,
            seller,
            quantity,
            itemOffered: {
              sku: itemOffered.sku,
              image: itemOffered.image,
              name: itemOffered.name,
              additionalProperty: itemOffered.additionalProperty,
            },
          })
        ),
      },
    },
  })

  if (validated) {
    return {
      id: validated.order.orderNumber,
      items: validated.order?.acceptedOffer?.map((item: CartItem) => ({
        ...item,
        id: item.itemOffered.sku,
      })) as CartItem[],
      messages: validated.messages,
    }
  }

  return null
}

export const cartStore = {
  ...defaultCartStore,
  addItem: (item: Omit<CartItem, 'id'>) => {
    const cartItem = {
      ...item,
      id: item.itemOffered.sku,
    }

    defaultCartStore.addItem(cartItem)
  },
}

export const useCart = (): UseCartReturn => {
  const cart: Cart = useStore(cartStore)
  const isValidating = useStore(validationStore)
  const { messages, items } = cart

  return useMemo(() => {
    let totalItems = 0
    let total = 0
    let subTotal = 0
    const gifts: CartItem[] = []
    const filteredItems: CartItem[] = []

    items.forEach((item) => {
      const { quantity, price } = item

      if (isGift(item)) {
        gifts.push(item)
      } else {
        filteredItems.push(item)
        totalItems += quantity
        total += price * quantity

        const {
          listPrice,
          itemOffered: { unitMultiplier, measurementUnit },
        } = item

        subTotal +=
          formatListPrice(listPrice, unitMultiplier, measurementUnit) * quantity
      }
    })

    return {
      ...cart,
      isValidating,
      messages,
      gifts,
      items: filteredItems,
      totalUniqueItems: items.length,
      totalItems,
      total,
      subTotal,
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValidating, items, messages])
}
