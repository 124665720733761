import React from 'react'

import { useLastSeenProducts } from '../../../hooks/lastSeenProducts/useLastSeenProducts'
import { useVerticalShelfCarouselProps } from '../../../hooks/useVerticalShelfCarouselProps'
import { ProductShelf } from '../../ui/ProductShelf'

export function LastSeenProducts() {
  const { hasSeeMore, isLoading, products } = useLastSeenProducts(4)
  const propsCarousel = useVerticalShelfCarouselProps()

  const link = hasSeeMore ? '/o-que-te-interessou' : undefined

  return (
    <ProductShelf
      products={products}
      id="vitrine-ultimos-produtos-vistos"
      variant="horizontal"
      hasQuickView={false}
      backgroundColor="var(--color-grayScale-light)"
      loading={isLoading}
      title="O que te interessou"
      link={link}
      propsCarousel={propsCarousel}
    />
  )
}
