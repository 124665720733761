import { Button } from '@faststore/ui'
import React, { useState } from 'react'

import { DiscountBadge } from '../../ui/Badge'
import { ClubProductTag } from '../../ui/ClubProductTag'
import { Image } from '../../ui/Image'

import type { ProductDetailsGallery } from '../../../types/ProductDetailsGallery'

export const GalleryImages = ({
  productImages,
  listPriceCustom,
  spotPriceCustom,
  clubValidation,
  hasDiscount,
}: ProductDetailsGallery) => {
  const [selectedImage, setSelectedImage] = useState(0)

  return (
    <>
      {productImages.length > 1 && (
        <div data-testid="side-images" className="product-details__image-side">
          {productImages.map((image, index) => (
            <Button
              key={image.url}
              className={
                index === selectedImage
                  ? 'product-details__image-side-container selected'
                  : 'product-details__image-side-container'
              }
              onMouseEnter={() => setSelectedImage(index)}
              onClick={() => setSelectedImage(index)}
            >
              <Image
                baseUrl={image.url}
                sourceWidth={720}
                aspectRatio={1}
                width={60}
                layout="constrained"
                backgroundColor="var(--color-grayScale-faded)"
                options={{
                  fitIn: true,
                }}
                alt={image.alternateName}
                loading="lazy"
                objectFit="contain"
              />
            </Button>
          ))}
        </div>
      )}

      <div className="product-details__image-principal">
        {clubValidation ? (
          <ClubProductTag text="Clube" />
        ) : (
          <div className="discount-favorite">
            {hasDiscount && (
              <DiscountBadge
                listPrice={listPriceCustom}
                spotPrice={spotPriceCustom}
              />
            )}
          </div>
        )}

        <Image
          data-testid="principal-image"
          baseUrl={productImages[selectedImage].url}
          sourceWidth={720}
          aspectRatio={1}
          width={568}
          breakpoints={[250, 360, 480, 720]}
          layout="constrained"
          backgroundColor="var(--color-grayScale-faded)"
          options={{
            fitIn: true,
          }}
          alt={productImages[selectedImage].alternateName}
          loading="lazy"
          objectFit="contain"
        />
      </div>
    </>
  )
}
