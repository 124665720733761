import { Link } from 'gatsby'
import React from 'react'

import { useAnalyticsEvents } from '../../../hooks/useAnalyticsEvents'
import { Image } from '../Image'

import type { ImageWithAlt } from '../../../types/ClubCard/ClubCardBenefits'

import './shortcut.scss'

interface ShortcutProps {
  text: string
  icon: ImageWithAlt
  href: string
  location: string
  className?: string
}

export const Shortcut = ({
  text,
  icon,
  href,
  className = '',
  location,
}: ShortcutProps) => {
  const { genericTaggingEvent } = useAnalyticsEvents()
  if (!text || !icon?.src || !href) {
    return null
  }

  const handleClick = () => {
    genericTaggingEvent('header_shortcut', {
      name: text,
      location: location,
    })
  }

  return (
    <Link to={href} className={`shortcut ${className}`} onClick={handleClick}>
      <div className="shortcut-image">
        <Image alt={icon?.alt} baseUrl={icon?.src} width={24} height={24} />
      </div>
      <p className="shortcut-text">{text}</p>
    </Link>
  )
}
