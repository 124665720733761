import axios from 'axios'
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from 'react'

import { useRegionalizationContext } from './RegionalizationContext'
import { useStoreContext } from './StoreContext'
import { sessionStore, useSession, validateSession } from '../sdk/session'
import { getProfileData } from '../utils/getProfileData'

import type { Profile } from '../types/Club'
import type { ProductsData } from '../types/RepeatOrder'

interface UserInfos {
  id?: string
  email?: string
  givenName?: string
  familyName?: string
  sessionId?: string
  document?: string
  phone?: string
  cartonista?: boolean
}

interface ClubContextProps {
  children?: React.ReactNode
  isClubClient?: boolean
  userInfos?: UserInfos
  updateOrderForm?: (orderFormId: string, userEmail?: string) => Promise<void>
  productsMostPurchased?: ProductsData['products'] | null
  setProductsMostPurchased?: React.Dispatch<
    React.SetStateAction<ProductsData['products'] | null>
  >
  isLoading?: boolean
}

export const ClubContext = createContext<ClubContextProps | null>(null)

export const ClubContextProvider = ({ children }: ClubContextProps) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isClubClient, setIsClubClient] = useState(false)
  const [userInfos, setUserInfos] = useState<UserInfos>({})
  const [productsMostPurchased, setProductsMostPurchased] = useState<
    ProductsData['products'] | null
  >(null)

  const { postalCode } = useRegionalizationContext()
  const { isValidating, ...currentSession } = useSession()
  const { hasClub, salesChannels } = useStoreContext()
  const { email } = userInfos

  const hasValidatedRef = useRef(false)

  const isClubSalesChannel = useMemo(() => {
    const currentSC = JSON.parse(currentSession?.channel ?? '')?.salesChannel

    return currentSC === String(salesChannels?.club)
  }, [currentSession?.channel, salesChannels?.club])

  // save the user's email in the orderForm
  const updateOrderForm = useCallback(
    async (orderFormId: string) => {
      if (!email && !isClubClient) {
        return
      }

      try {
        await axios.patch('/api/updateOrderForm', {
          orderFormId,
          userEmail: email,
        })
      } catch (err) {
        throw new Error(`Error update order form  in club context : ${err}`)
      }
    },
    [email, isClubClient]
  )

  const changeSession = useCallback(
    async (salesChannel?: number | null) => {
      const session = {
        ...currentSession,
        postalCode: postalCode ?? null,
        channel: JSON.stringify({
          salesChannel: String(salesChannel),
        }),
      }

      try {
        const valideteSession = await validateSession({ ...session })

        if (valideteSession) {
          sessionStore.set(valideteSession)
        }
      } catch (err) {
        throw new Error(`Error on change session : ${err}`)
      }
    },
    [currentSession, postalCode]
  )

  const handleProfile = useCallback(async (profile?: Profile) => {
    const {
      id,
      email: newEmail,
      lastName,
      firstName,
      sessionId,
      document,
      isClub,
      cartonista,
    } = profile ?? (await getProfileData())

    setUserInfos({
      id: id ?? '',
      email: newEmail ?? '',
      familyName: lastName ?? '',
      givenName: firstName ?? '',
      sessionId: sessionId ?? '',
      document: document ?? '',
      cartonista,
    })

    if (isClub) {
      setIsClubClient(true)
    }

    setIsLoading(false)
  }, [])

  const handleClubClient = useCallback(async () => {
    try {
      const profile = await getProfileData()

      const isClubMember = profile?.isClub

      handleProfile(profile)

      if (isClubMember) {
        setIsClubClient(true)

        //  Sales channel will only be changed when it is not from the club
        if (!isClubSalesChannel) {
          await changeSession(salesChannels?.club)
        }

        // Returns a session to a pattern when the login cookie expires
      } else if (isClubSalesChannel) {
        setIsClubClient(false)
        await changeSession(salesChannels?.default)
      }

      setIsLoading(false)
    } catch (error) {
      throw new Error(`Error in club validation: ${error}`)
    }
  }, [isClubSalesChannel, currentSession, postalCode])

  useEffect(() => {
    // Run only when session is validated for the first time
    if (isValidating === false && !hasValidatedRef.current) {
      hasValidatedRef.current = true

      setIsLoading(true)

      if (hasClub) {
        handleClubClient()
      } else {
        handleProfile()
      }
    }
  }, [isValidating])

  return (
    <ClubContext.Provider
      value={{
        isClubClient,
        userInfos,
        productsMostPurchased,
        setProductsMostPurchased,
        updateOrderForm,
        isLoading,
      }}
    >
      {children}
    </ClubContext.Provider>
  )
}

export const useClubContext = () => {
  const context = useContext(ClubContext)

  if (!context) {
    throw new Error('useClubContext must be used within a ClubContextProvider')
  }

  return context
}
