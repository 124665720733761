import './points-wrapper.scss'

import React from 'react'

import { Points } from '../../Benefits/Points'

import type { Points as PointsProps } from '../../../../types/ClubCard/ClubCardUser'

export const PointsWrapper = ({
  visibility,
  title,
  text,
  linkText,
  href,
  icon,
  list,
  lastList,
}: PointsProps) => {
  if (!visibility || !list?.length) {
    return null
  }

  const button =
    href && linkText
      ? {
          href,
          text: linkText,
        }
      : undefined

  return (
    <Points
      visibility={visibility}
      title={title}
      text={text}
      icon={icon}
      list={list}
      button={button}
      className="club-card-user-points"
      lastList={lastList}
    />
  )
}
