import type { Address } from '@plurix/ecom-components'

export const HAS_WINDOW = typeof window !== 'undefined'
export const HAS_DOCUMENT = typeof document !== 'undefined'
export const HAS_LOCAL_STORAGE = typeof localStorage !== 'undefined'

export const MAX_SHELFS_CAROUSEL = 8

export const ITEMS_PER_PAGE = 20

export const windowGlobal = typeof window !== 'undefined' ? window : undefined
export const navigatorGlobal =
  typeof navigator !== 'undefined' ? navigator : undefined

export const defaultSession = {
  currency: {
    code: 'BRL',
    symbol: 'R$',
  },
  locale: 'pt-br',
  channel: '{"salesChannel":"1","regionId":""}',
  country: 'BRA',
  postalCode: null,
  person: null,
}

export const emptyAdress: Address = {
  bairro: '',
  cep: '',
  complemento: '',
  ddd: '',
  gia: '',
  ibge: '',
  localidade: '',
  logradouro: '',
  siafi: '',
  uf: '',
  error: '',
}

export const pesavelUND = 'Venda por unidade'

export const defaultSort = 'score_desc'

export const activateOrderStatus = [
  'order-created',
  'on-order-completed',
  'payment-pending',
  'waiting-for-order-authorization',
  'waiting-for-seller-decision',
  'replaced',
  'order-accepted',
  'approve-payment',
  'payment-approved',
  'window-to-cancel',
  'ready-for-handling',
  'start-handling',
  'handling',
].join(',')

export const orderAditionalFields =
  'shippingData{logisticsInfo{deliveryChannel,deliveryWindow{startDateUtc,endDateUtc}}}'

export const clubHeaderPaths = ['/club', '/aceitar-termos-promocao']

export const emptyOrder = {
  items: [],
  totals: [],
  creationDate: '',
  orderId: '',
}
