import { navigate } from 'gatsby'
import React from 'react'

import { useAnalyticsEvents } from '../../../hooks/useAnalyticsEvents'
import { showSection } from '../../../utils/showSection'

import type { showSectionVariables } from '../../../utils/showSection'

import './club-advantages.scss'

interface Props {
  isActive: boolean
  title: string
  description: string
  list: {
    benefit: string
    icon: {
      src: string
      alt: string
    }
  }[]
  button: {
    title: string
    buttonRedirect: string
  }
  visibilityConfig: showSectionVariables
}

export const ClubAdvantages = (props: Props) => {
  const {
    isActive,
    title,
    description,
    list,
    button: { title: titleButton, buttonRedirect },
    visibilityConfig,
  } = props
  const { genericTaggingEvent } = useAnalyticsEvents()

  if (!isActive) return

  if (!showSection(visibilityConfig)) return

  return (
    <div id="club-advantages" className="container-club-advantages">
      <h1>{title}</h1>
      <h3>{description}</h3>
      <div className="container-icons">
        {list?.map(({ benefit, icon }, index) => (
          <div className="container-icon" key={`${benefit}-${index}`}>
            <img src={icon.src} alt={icon.alt} width={56} height={56} />
            <p>{benefit}</p>
          </div>
        ))}
      </div>
      <button
        type="button"
        className="button-club-advantages"
        onClick={() => {
          navigate(buttonRedirect)
          genericTaggingEvent('friend_club_plus', {})
        }}
      >
        {titleButton}
      </button>
    </div>
  )
}
