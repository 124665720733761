import React from 'react'

import { useClubContext } from '../../../contexts/ClubContext'
import { Banner } from '../../sections/Banner'

import type { BannerProps } from '../../sections/Banner'

export const ClubCardBanner = ({ data }: BannerProps) => {
  const { userInfos } = useClubContext()

  if (userInfos?.cartonista) {
    return null
  }

  return <Banner data={data} />
}
