import { Skeleton } from '@acctglobal/skeleton'
import React from 'react'

import { InstitucionalCarousel } from './InstitucionalCarousel'
import { Image } from '../../ui/Image'

import type { InstitutionalBanners } from '.'

interface InstitutionalBannerProps {
  banners: InstitutionalBanners[] | undefined
  width: number
  position: 'Top' | 'Bottom'
}

export const InstitutionalBanner = ({
  banners,
  width,
  position,
}: InstitutionalBannerProps) => {
  if (!banners) {
    return null
  }

  if (!width) {
    return (
      <div className="institutional-banner-loading">
        <Skeleton
          responsiveImageSSR={{ srcWidth: 288, srcHeight: 102 }}
          backgroundColor="var(--color-grayScale-faded)"
        />
        <Skeleton
          responsiveImageSSR={{ srcWidth: 1408, srcHeight: 280 }}
          backgroundColor="var(--color-grayScale-faded)"
        />
      </div>
    )
  }

  const sourceWidth = width <= 768 ? 288 : 1408
  const sourceHeight = width <= 768 ? 102 : 280

  if (
    position === 'Top' &&
    typeof banners[0].srcTopImage === 'string' &&
    typeof banners[0].srcTopImageMobile === 'string'
  ) {
    return (
      <Image
        baseUrl={
          width <= 768 ? banners[0].srcTopImageMobile : banners[0].srcTopImage
        }
        alt={banners[0].alt}
        sourceWidth={sourceWidth}
        sourceHeight={sourceHeight}
        layout="fullWidth"
        backgroundColor="var(--color-grayScale-faded)"
        loading="lazy"
      />
    )
  }

  if (
    position === 'Top' &&
    Array.isArray(banners[0].srcTopImage) &&
    Array.isArray(banners[0].srcTopImageMobile)
  ) {
    return (
      <InstitucionalCarousel
        banners={banners[0].srcTopImage}
        bannersMobile={banners[0].srcTopImageMobile}
        imgProps={{
          alt: banners[0].alt,
          width,
          sourceHeight,
          sourceWidth,
        }}
      />
    )
  }

  if (
    typeof banners[0].srcBottomImage === 'string' &&
    typeof banners[0].srcBottomImageMobile === 'string'
  ) {
    return (
      <img
        style={{ margin: '24px 0' }}
        src={
          width <= 768
            ? banners[0].srcBottomImageMobile
            : banners[0].srcBottomImage
        }
        width="100%"
      />
    )
  }

  if (
    typeof banners[0].srcBottomImage === 'object' &&
    !('image' in banners[0].srcBottomImage) &&
    typeof banners[0].srcBottomImageMobile === 'object' &&
    !('image' in banners[0].srcBottomImageMobile)
  ) {
    return (
      <div className="institutional-banners-container">
        {width <= 768
          ? banners[0].srcBottomImageMobile.map((banner) => (
              <img key={banner} src={banner} width="100%" alt="bottom" />
            ))
          : banners[0].srcBottomImage.map((banner) => (
              <img key={banner} src={banner} width="100%" alt="bottom" />
            ))}
      </div>
    )
  }

  return null
}
