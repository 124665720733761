import React from 'react'

import { useVerticalShelfCarouselProps } from '../../../hooks/useVerticalShelfCarouselProps'
import { useProductsQuery } from '../../../sdk/product/useProductsQuery'
import { ProductShelf } from '../../ui/ProductShelf'

import type { ProductsQueryResponse } from '../../../types/Product'

interface HistorySearchShelfProps {
  term: string
}

const PRODUCTS_TO_FETCH = 8
const PRODUCTS_TO_SHOW = 4

export const HistorySearchShelf = ({ term }: HistorySearchShelfProps) => {
  const { data, isLoading } = useProductsQuery({
    sort: 'discount_desc',
    term,
    first: PRODUCTS_TO_FETCH,
  })

  const products: ProductsQueryResponse['node'][] = (data?.edges ?? []).map(
    ({ node }) => node
  )

  const propsCarousel = useVerticalShelfCarouselProps()

  return (
    <ProductShelf
      products={products}
      limit={PRODUCTS_TO_SHOW}
      id="vitrine-relacionado-com-suas-buscas"
      variant="horizontal"
      backgroundColor="var(--color-grayScale-light)"
      loading={isLoading}
      title="Você também pode gostar"
      propsCarousel={propsCarousel}
    />
  )
}
