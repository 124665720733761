import './accordion-v2.scss'

import React from 'react'

import ArrowIcon from '../../../assets/ArrowIcon'
import { DynamicTitle } from '../../common/DynamicTitle'

interface AccordionV2Props {
  selectedTitle: string | undefined
  setSelectedTitle: React.Dispatch<React.SetStateAction<string | undefined>>
  title: string
  titleLevel?: number
  children?: React.ReactNode
  className?: string
  handleSelect?: HandleSelectAccordionOption
}

export type HandleSelectAccordionOption = (params: {
  isSelected: boolean
  title: string
}) => void

export const AccordionV2 = ({
  selectedTitle,
  setSelectedTitle,
  title,
  titleLevel = 3,
  children,
  className = '',
  handleSelect,
}: AccordionV2Props) => {
  const isSelected = selectedTitle === title

  const handleAccordion = () => {
    handleSelect?.({ isSelected, title })
    setSelectedTitle(isSelected ? undefined : title)
  }

  if (!title) {
    return null
  }

  return (
    <div className={`accordionV2 ${className} ${isSelected ? 'selected' : ''}`}>
      <button className="accordionV2-container" onClick={handleAccordion}>
        <DynamicTitle
          className="accordionV2-title"
          titleLevel={titleLevel}
          title={title}
        />
        <ArrowIcon
          rotate={isSelected ? 'Up' : 'Down'}
          color="var(--color-main)"
        />
      </button>
      {isSelected && children}
    </div>
  )
}
