import React from 'react'

import { ButtonPoints } from './ButtonPoints'
import HeartCircle from '../../../../assets/HeartCircle'
import useWindowDimensions from '../../../../hooks/useWindowDimensions'
import { AdaptiveCarousel } from '../../../common/AdaptiveCarousel'
import { BoldTextParts } from '../../../common/BoldTextParts'
import { DynamicTitle } from '../../../common/DynamicTitle'
import { PointsCard } from '../../../ui/Points/PointsCard'
import { PointsTextCard } from '../../../ui/Points/PointsTextCard'

import type { Points as PointBenefitsProps } from '../../../../types/ClubCard/ClubCardBenefits'

import './points.scss'

interface PointsProps extends PointBenefitsProps {
  button?: {
    href: string
    text: string
  }
  className?: string
  lastList?: {
    isVisible: boolean
    title: string
    redirect: string
  }
}

export const Points = ({
  visibility,
  title,
  text,
  list,
  icon,
  button,
  className = '',
  lastList,
}: PointsProps) => {
  const { width, isMobile } = useWindowDimensions()
  const isWidthMobile = width && width <= 768

  if (!visibility || !list?.length) return null

  return (
    <div className={`club-card-benefits-points grid-content ${className}`}>
      {title && (
        <DynamicTitle className="points-title" titleLevel={2} title={title} />
      )}
      {button?.href && button?.text && (
        <div className="points-infos">
          <div className="points-infos-quantity">
            {/* Alterar mock, após a integração */}
            <p className="points-infos-quantity-text">13.965</p>
            <HeartCircle />
          </div>
          {!isWidthMobile && <ButtonPoints {...button} />}
        </div>
      )}
      {text && (
        <div className="points-subTitle">
          <BoldTextParts text={text} />
        </div>
      )}
      <AdaptiveCarousel
        quantity={list?.length}
        quantityToCarousel={8}
        activeBlur={false}
        forceCarousel={!!isMobile}
        propsCarousel={{
          gapItems: 0,
          qtyItems: !isWidthMobile ? 7 : 2,
          itemWidthProp: 138,
        }}
      >
        {list?.map((itemPoint, index) => (
          <PointsCard
            key={`${itemPoint?.text}-${index}`}
            icon={icon}
            image={itemPoint?.image}
            points={itemPoint?.quantity}
            text={itemPoint?.text}
          />
        ))}
        {lastList && lastList?.isVisible && (
          <PointsTextCard
            key={lastList?.title}
            text={lastList?.title}
            redirect={lastList?.redirect}
          />
        )}
      </AdaptiveCarousel>
      {isWidthMobile && <ButtonPoints {...button} />}
    </div>
  )
}
