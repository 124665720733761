import { graphql, useStaticQuery } from 'gatsby'

const useCmsFooter = () => {
  const data = useStaticQuery(graphql`
    query CmsFooterQuery {
      cmsFooter {
        sections {
          data
        }
      }
    }
  `)

  const storeInfos: string | undefined =
    data?.cmsFooter?.sections?.[0]?.data?.infos

  return { storeInfos }
}

export default useCmsFooter
