import { Coupons } from '@plurix/ecom-components'
import { Link } from 'gatsby'
import React from 'react'

import { DynamicTitle } from '../../../../components/common/DynamicTitle'
import { useStoreContext } from '../../../../contexts/StoreContext'
import { useAnalyticsEvents } from '../../../../hooks/useAnalyticsEvents'
import useWindowDimensions from '../../../../hooks/useWindowDimensions'
import { useCart } from '../../../../sdk/cart'
import { showSection } from '../../../../utils/showSection'

import type { showSectionVariables } from '../../../../utils/showSection'

import '../../../ClubCard/Coupons/coupons.scss'
import './coupons-showcase.scss'

interface Props {
  visibilityConfig: showSectionVariables
  isActive: boolean
  title: string
  description: string
}

export const CampaignCouponsShowCase = (props: Props) => {
  const { isActive, title, description, visibilityConfig } = props
  const { width } = useWindowDimensions()
  const { account } = useStoreContext()
  const { id: orderFormId, items } = useCart()
  const { genericTaggingEvent } = useAnalyticsEvents()

  if (!showSection(visibilityConfig) || !isActive) return

  const handleClick = () => {
    genericTaggingEvent('discount_coupon', { name: 'click see all' })
  }

  return (
    <div className="grid-content">
      <div className="club-card-user-coupons">
        <div className="coupons-header">
          <DynamicTitle
            title={title}
            titleLevel={2}
            className="coupons-title"
          />
          <Link to="/cupons-de-oferta" onClick={handleClick}>
            Ver Tudo
          </Link>
        </div>

        <p className="coupons-subtitle">{description}</p>
      </div>
      <Coupons
        account={account}
        builder="faststore"
        hasItemsInCart={items.length > 0}
        orderFormId={orderFormId}
        genericTaggingEvent={genericTaggingEvent}
        disableFirstEmptyModal
        qtdCouponsView={3}
      />

      {width && width < 420 && (
        <Link
          to="/cupons-de-oferta"
          className="button-see-more"
          onClick={handleClick}
        >
          Ver todos cupons disponíveis
        </Link>
      )}
    </div>
  )
}
