import React, { createContext, useCallback, useContext, useMemo } from 'react'

export interface SpecialPromotions {
  collectionID: string
  flagText: string
  colorCode: string
}
export interface ClusterHighlights {
  id: string
  name: string
}

interface SpecialPromotionsContextProps {
  children?: React.ReactNode
  allPromotions?: SpecialPromotions[]
  searchPromotion?: (
    clusterHighlights?: ClusterHighlights[]
  ) => SpecialPromotions | undefined
}

const SpecialPromotionsContext =
  createContext<SpecialPromotionsContextProps | null>(null)

export const SpecialPromotionsContextProvider = ({
  children,
  allPromotions,
}: SpecialPromotionsContextProps) => {
  const searchPromotion = useCallback(
    (clusterHighlights?: ClusterHighlights[]) => {
      const searchedPromotion = allPromotions?.filter((promotion) =>
        clusterHighlights?.some(
          (cluster) => cluster?.id === promotion?.collectionID
        )
      )

      if (searchedPromotion?.length !== 1) {
        return
      }

      return searchedPromotion[0]
    },
    [allPromotions]
  )

  const values = useMemo(
    () => ({
      searchPromotion,
    }),
    []
  )

  return (
    <SpecialPromotionsContext.Provider value={values}>
      {children}
    </SpecialPromotionsContext.Provider>
  )
}

export const useSpecialPromotionsContext = () => {
  const context = useContext(SpecialPromotionsContext)

  if (!context) {
    throw new Error(
      'useSpecialPromotionsContext must be used within a SpecialPromotionsContextProvider'
    )
  }

  return context
}
