import React from 'react'
import './clubProductTag.scss'

interface ClubProductTagProps {
  text: string
  afterPriceLocation?: boolean
}

export const ClubProductTag = ({
  text,
  afterPriceLocation,
}: ClubProductTagProps) => {
  return (
    <div
      className={
        afterPriceLocation
          ? `club-product-flag-after-price `
          : 'club-product-flag'
      }
    >
      <p>{text}</p>
    </div>
  )
}
