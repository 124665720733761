import { Carousel } from '@plurix/ecom-components'
import { Link } from 'gatsby'
import React from 'react'

import { useAnalyticsEvents } from '../../../hooks/useAnalyticsEvents'
import { useAnotherScPrices } from '../../../hooks/useAnotherScPrices'
import { carouselProps } from '../../../utils/carouselProps'
import ProductCard from '../../product/ProductCard'
import { SeeMoreCard } from '../../sections/MultipleSearch/MultipleSearchShelfs/SeeMoreCard/SeeMoreCard'

import type { ProductsQueryResponse } from '../../../types/Product'

import './CarouselWithSeeMore.scss'

type CarouselWithSeeMoreProps = {
  products: ProductsQueryResponse['node'][]
  title: string
  description?: string
  link: string
  text: string
  location: string
  width: number
  customQtyItems?: number
  containerId?: string
}

export const CarouselWithSeeMore = ({
  products,
  title,
  description,
  link,
  text,
  location,
  customQtyItems,
  containerId,
  width,
}: CarouselWithSeeMoreProps) => {
  const { genericTaggingEvent } = useAnalyticsEvents()
  const { getAnotherScPrices } = useAnotherScPrices(products)

  const productsInStock = products.filter(
    (product) =>
      product?.offers?.offers?.[0]?.availability ===
      'https://schema.org/InStock'
  )

  if (productsInStock.length <= 0) {
    return null
  }

  const hasSeeAll = productsInStock.length > 4

  const handleSeeAll = () => {
    genericTaggingEvent('see_all', { name: location })
  }

  const { qtyItems, bullet, arrow } = carouselProps(width, customQtyItems)
  const titleLevel = 3

  return (
    <section
      id={containerId}
      className="page__section shelf-section-grid shelf-section-content-grid carousel-with-see-more-container"
    >
      <div className="carousel-with-see-more-header">
        <div className="carousel-with-see-more-row">
          <h2>{title}</h2>
          {hasSeeAll && (
            <Link
              className="carousel-with-see-more-header-link"
              to={link}
              onClick={handleSeeAll}
            >
              Ver Tudo
            </Link>
          )}
        </div>
        {description && <h4>{description}</h4>}
      </div>

      <div id={width > 1280 && !hasSeeAll ? 'carousel-view-arrow' : ''}>
        <Carousel
          bullet={bullet}
          arrow={{
            ...arrow,
            isVisible: !!(width > 1280 && hasSeeAll),
          }}
          qtyItems={qtyItems}
        >
          {productsInStock.map((product, index) => {
            if (index <= 4) {
              const anotherScPrices = getAnotherScPrices(product.sku)

              return (
                <ProductCard
                  key={product.id}
                  product={product}
                  location={location}
                  {...anotherScPrices}
                  titleLevel={titleLevel}
                />
              )
            }

            return null
          })}

          {hasSeeAll && (
            <SeeMoreCard seeMoreLink={link} text={text} location={location} />
          )}
        </Carousel>
      </div>
    </section>
  )
}
