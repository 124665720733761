import React from 'react'

const HeartCircle = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="10" cy="10" r="10" fill="var(--loyalty-color-icon-default)" />
    <path
      d="M14.2076 13.3442C16.2973 13.3442 17.9914 11.6363 17.9914 9.52952C17.9914 7.42273 16.2973 5.71484 14.2076 5.71484C12.1179 5.71484 10.4238 7.42273 10.4238 9.52952C10.4238 11.6363 12.1179 13.3442 14.2076 13.3442Z"
      fill="#797EB5"
    />
    <path
      d="M17.9926 9.52423C17.9926 13.7323 14.6046 17.1434 10.425 17.1434C6.24541 17.1434 2.85742 13.7323 2.85742 9.52423C2.85742 7.42039 4.55141 5.71484 6.64101 5.71484C8.7306 5.71484 10.4246 7.42039 10.4246 9.52423C10.4246 11.6281 12.1186 13.3336 14.2082 13.3336C16.2978 13.3336 17.9918 11.6281 17.9918 9.52423H17.9926Z"
      fill="white"
    />
  </svg>
)

export default HeartCircle
