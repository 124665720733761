import React from 'react'

import { PriceCustom } from '../../../../ui/PriceCustom'
import './product-card-club-prices.scss'

interface ProductCardClubPricesProps {
  measurementUnit?: string
  clubPrice?: number
  hasUndQtd?: number | string
  hasUnd?: string
}

export const ProductCardClubPrices = ({
  measurementUnit,
  clubPrice,
  hasUndQtd,
  hasUnd,
}: ProductCardClubPricesProps) => {
  const isMeasured = measurementUnit !== 'un'

  return (
    <div className="container-club-price">
      <div className="club-price">
        <p className="club-text">Clube:</p>
        <p className="club-price-text">
          <PriceCustom className="club-price-custom" price={clubPrice} />
          {isMeasured && (
            <span className="club-measure-text">
              /{hasUndQtd}
              {hasUnd}
            </span>
          )}
        </p>
      </div>
    </div>
  )
}
