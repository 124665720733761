import React from 'react'

import { AppStore, PlayStore } from '../../../assets/downloadIcons'
import { BoldTextParts } from '../../common/BoldTextParts'

import type { ClubCardGetCardPageSectionData } from '../../../types/ClubCard/ClubCardGetCard'

import './steps.scss'

interface StepsProps {
  steps: ClubCardGetCardPageSectionData['steps']
}

export function Steps({ steps }: StepsProps) {
  const { visibility, list, downloadStep, links } = steps

  if (!visibility) {
    return null
  }

  return (
    <div className="club-card-get-card-steps grid-content">
      {list?.map((step, index) => (
        <div key={step} className="step-container" data-testid="step-container">
          <div className="step-content">
            <span>{index + 1}</span>
            <BoldTextParts text={step} />
          </div>

          {index + 1 === downloadStep && (
            <div
              className="download-container"
              data-testid="download-container"
            >
              <a
                href={links.web.apple}
                target="_blank"
                rel="noopener noreferrer"
                data-testid="apple-download"
              >
                <AppStore />
              </a>

              <a
                href={links.web.android}
                target="_blank"
                rel="noopener noreferrer"
                data-testid="android-download"
              >
                <PlayStore />
              </a>
            </div>
          )}
        </div>
      ))}
    </div>
  )
}
