import { Link } from 'gatsby'
import React from 'react'

import { useAnalyticsEvents } from '../../../../hooks/useAnalyticsEvents'
import { DynamicTitle } from '../../../common/DynamicTitle'

import './points-text-card.scss'

interface PointsCardProps {
  text: string
  redirect: string
  className?: string
}

export const PointsTextCard = ({
  text,
  redirect,
  className = '',
}: PointsCardProps) => {
  const { genericTaggingEvent } = useAnalyticsEvents()
  const handleClick = () => {
    genericTaggingEvent('order_your_visa_card_now', {})
  }

  return (
    <Link to={redirect} onClick={handleClick}>
      <div className={`pointsTextCard ${className}`}>
        <DynamicTitle className="pointsCard-text" titleLevel={2} title={text} />
      </div>
    </Link>
  )
}
