import React, { useMemo } from 'react'

import { defaultSort } from '../../../../constants'
import { useStoreContext } from '../../../../contexts/StoreContext'
import { useProductWithLowStock } from '../../../../hooks/useProductWithLowStock'
import useWindowDimensions from '../../../../hooks/useWindowDimensions'
import { useProductsQuery } from '../../../../sdk/product/useProductsQuery'
import { getClubName } from '../../../../utils/formatAccountName'
import { CarouselWithSeeMore } from '../../../common/CarouselWithSeeMore'
import { ProductShelfSkeleton } from '../../ProductShelf'

interface ClubProductsSectionProps {
  customQtyItems?: number
  title?: string
  description?: string
  location: string
}

export const ClubProductsSection = ({
  customQtyItems,
  title,
  description,
  location,
}: ClubProductsSectionProps) => {
  const { data: productList } = useProductsQuery({
    first: 10,
    sort: defaultSort,
    selectedFacets: [{ key: 'preco2', value: 'true' }],
  })

  const products = useMemo(
    () => productList?.edges.map(({ node }) => node),
    [productList]
  )

  const { productsWithLowStock, loading: productsWithLowStockLoading } =
    useProductWithLowStock(products)

  const { width } = useWindowDimensions()

  const { account } = useStoreContext()
  const clubName = getClubName(account)

  if (productsWithLowStockLoading || !width) {
    return (
      <section className="page__section / shelf-section-grid shelf-section-content-grid ">
        <ProductShelfSkeleton />
      </section>
    )
  }

  if (!productsWithLowStock?.length) {
    return null
  }

  return (
    <CarouselWithSeeMore
      products={productsWithLowStock}
      title={title ?? clubName}
      description={description}
      link="/s/?clubProducts"
      text="Seus produtos favoritos!"
      location={location}
      customQtyItems={customQtyItems}
      width={width}
    />
  )
}
