import { Skeleton } from '@acctglobal/skeleton'
import { Carousel } from '@plurix/ecom-components'
import { Link } from 'gatsby'
import React from 'react'

import { ProductShelfSkeleton } from '.'
import { useAnalyticsEvents } from '../../../hooks/useAnalyticsEvents'
import { useAnotherScPrices } from '../../../hooks/useAnotherScPrices'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import { useProductsQuery } from '../../../sdk/product/useProductsQuery'
import { carouselProps } from '../../../utils/carouselProps'
import ProductCard from '../../product/ProductCard'
import WhenVisible from '../../ui/WhenVisible/WhenVisible'

import type { ProductsQueryWithEdges } from '../../../types/Product'
import type { Variant } from '../../product/ProductCard'
import type { Facet } from '@faststore/sdk/dist/types'
import type { CarouselProps } from '@plurix/ecom-components/dist/packages/Carousel/Carousel'

import './product-shelf.scss'

interface ProductShelfProps {
  selectedFacets?: Facet | Facet[]
  title: string
  link?: string
  id: string
  backgroundColor?: string
  productQueryQuantity?: number
  variant?: Variant
  buttonSeeAll?: boolean
  maxCardQuantity?: number
  term?: string
  sort?: string
  propsCarousel?: Omit<CarouselProps, 'children'>
}

export const ProductShelf = ({
  selectedFacets,
  title,
  link,
  id,
  term,
  maxCardQuantity,
  buttonSeeAll = true,
  variant = 'vertical',
  sort = 'discount_desc',
  productQueryQuantity = 10,
  backgroundColor = '',
  propsCarousel,
}: ProductShelfProps) => {
  const { width } = useWindowDimensions()
  const { viewItemListEvent, genericTaggingEvent } = useAnalyticsEvents()

  const { data }: { data: ProductsQueryWithEdges } = useProductsQuery({
    sort,
    selectedFacets,
    term,
    first: productQueryQuantity,
  })

  const productList = data?.edges ?? []

  const products = productList
    .filter(
      (product) =>
        product.node?.offers?.offers?.[0]?.availability ===
        'https://schema.org/InStock'
    )
    .slice(0, maxCardQuantity)
    .map(({ node }) => node)

  const { getAnotherScPrices } = useAnotherScPrices(products)

  const handleViewItemList = () => {
    viewItemListEvent(products, id)
  }

  const handleSeeAll = () => {
    genericTaggingEvent('see_all', { name: id })
  }

  if (!width) {
    return (
      <section className="div-content-shelf-product grid-content">
        <Skeleton height={500} backgroundColor={backgroundColor} />
      </section>
    )
  }

  if (!data) {
    return (
      <section className="div-content-shelf-product grid-content">
        <ProductShelfSkeleton backgroundColor={backgroundColor} />
      </section>
    )
  }

  if (products.length <= 0) {
    return null
  }

  const props = carouselProps(width)

  return (
    <div
      className="div-content-shelf-product grid-content"
      data-shelf-cardType-variant={variant}
      style={{ backgroundColor }}
    >
      <div className="carousel-product-header">
        <h2 className="carousel-product-header-title">{title}</h2>
        {buttonSeeAll && (
          <Link to={link} onClick={handleSeeAll} data-store-link>
            Ver Tudo
          </Link>
        )}
      </div>

      <div className="div-content-shelf-product">
        <WhenVisible
          id={`product-shelf-${id}`}
          trigger={handleViewItemList}
          singleActivation
          isProductShelf
        >
          <Carousel {...props} {...propsCarousel}>
            {products.map((product) => {
              const anotherScPrices = getAnotherScPrices(product.sku)

              return (
                <div
                  className={
                    variant === 'vertical' ? undefined : 'product-card-wrapper'
                  }
                  key={product.id}
                >
                  <ProductCard
                    product={product}
                    location={id}
                    titleLevel={3}
                    variant={variant}
                    {...anotherScPrices}
                  />
                </div>
              )
            })}
          </Carousel>
        </WhenVisible>
      </div>
    </div>
  )
}
