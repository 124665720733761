import React from 'react'

import { useAnalyticsEvents } from '../../../hooks/useAnalyticsEvents'
import { Banner as BannerUI } from '../../ui/Banner'

import type { GenericBanner } from '../../../types/CMS'

export interface BannerProps {
  data?: GenericBanner
  className?: string
}

export const Banner = ({ data, className }: BannerProps) => {
  const { visibility, desktop, mobile, alt, promotionName, href } = data ?? {}
  const { selectPromotion } = useAnalyticsEvents()

  const handleClick = () => {
    selectPromotion({
      href,
      promotionName,
      locale: window.location.href,
    })
  }

  if (!visibility) {
    return null
  }

  return (
    <BannerUI
      href={href}
      src={desktop?.src}
      srcMobile={mobile?.src}
      alt={alt}
      bannerWidth={mobile?.width}
      bannerHeight={mobile?.height}
      bannerDeskWidth={desktop?.width}
      bannerDeskHeight={desktop?.height}
      className={className}
      handleClick={handleClick}
    />
  )
}
