import React, { createContext, useContext, useMemo } from 'react'

import { validateToggles } from '../utils/validateToggles'

import type {
  Logo,
  MenuLines,
  LoyaltyClubTexts,
  LoyaltyClubBanner,
  LoyaltyClubDownloadImages,
  PlpBanners,
  MosaicSection,
  MenuBannerWithLink,
  NotificationCMS,
  ModalInfosCMS,
  UtmCmsData,
  MaintenanceStore,
  CMSSection,
  ScratchPageContent,
  FooterPaymentMethodsProps,
} from '../types/CMS'
import type { SalesChannels } from '../types/SalesChannels'
import type { PricesAndHours } from '@plurix/ecom-components'

interface StoreInfosProps {
  account: string
  secureSubdomain: string
  checkoutUrl: string
  loginUrl: string
  accountUrl: string
  salesChannels: SalesChannels
  chatUrl: string
  devFeatureToggle?: { [key: string]: boolean }
}

interface CMSContextProps {
  featureToggle?: { [key: string]: boolean }

  restrictedMode?: {
    passKey: string
  }

  // header
  logo?: Logo
  allCategories?: any
  notificationsBar?: NotificationCMS
  downloadModal?: ModalInfosCMS
  menuFirst?: MenuLines[]
  menuSecond?: MenuLines[]
  departments?: MenuBannerWithLink[]

  // footer
  socialMediaLinks?: {
    linkWhatsApp?: string
    linkFacebook?: string
    linkInstagram?: string
    linkLinkedin?: string
  }
  storeInfos?: string

  // modals
  pricesAndHours?: PricesAndHours
  cookiesText?: string
  loyaltyClubTexts?: LoyaltyClubTexts
  loyaltyClubBanner?: LoyaltyClubBanner
  loyaltyClubDownloadImages?: LoyaltyClubDownloadImages
  loyaltyMosaicList?: MosaicSection

  // home page
  salesWhatsappOffers?: SalesWhatsappOffer[]
  maintenanceStore?: MaintenanceStore
  scratchPageContent?: ScratchPageContent

  // product listing pages
  plpBanners?: PlpBanners

  // product detail page
  freeShippingBaseValue?: number

  // footer payment methods
  footerPaymentMethods?: FooterPaymentMethodsProps[] | false

  utmPromotionBond?: UtmCmsData[]
  sections?: CMSSection[]
  foodRestrictionSection?: {
    specificationName: string
    specificationLabel: string
    specificationColor: string
  }[]
  storageRestrictionSection?: {
    specificationName: string
    specificationLabel: string
    specificationColor: string
  }[]
}

type SalesWhatsappOffer = {
  data: {
    name: string
    neighborhood: string
    street: string
    whatsappLink: string
  }
}

export interface StoreContextProps extends StoreInfosProps, CMSContextProps {
  children?: React.ReactNode

  // featureToggle/devFeatureToggle keys
  hasTabloid?: boolean
  hasTabloidV2?: boolean
  hasClub?: boolean
  hasClubWithRegion?: boolean
  hasClubRegister?: boolean
  hasAppLinks?: boolean
  hasWhatsappOffers?: boolean
  hasChangeOrder?: boolean
  hasClubPlusInDropdown?: boolean
}

const StoreContext = createContext<StoreContextProps | null>(null)

export const StoreContextProvider = ({
  children,
  featureToggle,
  devFeatureToggle,
  ...props
}: StoreContextProps) => {
  const { useTabloidV2: hasTabloidV2, ...toggles } = useMemo(
    () =>
      validateToggles(featureToggle, devFeatureToggle, [
        'hasTabloid',
        'useTabloidV2',
        'hasClub',
        'hasClubWithRegion',
        'hasClubRegister',
        'hasAppLinks',
        'hasWhatsappOffers',
        'hasChangeOrder',
        'hasClubPlusInDropdown',
      ] as const),
    [featureToggle, devFeatureToggle]
  )

  const value = useMemo(
    () => ({
      ...props,
      hasTabloidV2,
      ...toggles,
    }),
    [hasTabloidV2, props, toggles]
  )

  return <StoreContext.Provider value={value}>{children}</StoreContext.Provider>
}

export const useStoreContext = () => {
  const context = useContext(StoreContext)

  if (!context) {
    throw new Error(
      'useStoreContext must be used within a StoreContextProvider'
    )
  }

  return context
}
