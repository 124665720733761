import { Skeleton } from '@acctglobal/skeleton'
import { Carousel } from '@plurix/ecom-components'
import { Link } from 'gatsby'
import React, { useMemo } from 'react'

import { ProductShelfSkeleton } from '.'
import { useAnalyticsEvents } from '../../../hooks/useAnalyticsEvents'
import { useAnotherScPrices } from '../../../hooks/useAnotherScPrices'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import { carouselProps } from '../../../utils/carouselProps'
import { verifyProductInStock } from '../../../utils/products/verifyProductInStock'
import { DynamicTitle } from '../../common/DynamicTitle'
import ProductCard from '../../product/ProductCard'
import WhenVisible from '../WhenVisible/WhenVisible'

import type { CarouselPropsWithoutChildren } from '../../../hooks/useVerticalShelfCarouselProps'
import type { ProductsQueryResponse } from '../../../types/Product'
import type { Variant } from '../../product/ProductCard'

import './product-shelf.scss'

interface ProductShelfProps {
  products?: ProductsQueryResponse['node'][]
  limit?: number
  id: string
  variant?: Variant
  titleLevel?: number
  hasQuickView?: boolean
  backgroundColor?: string
  loading: boolean
  title: string
  link?: string
  propsCarousel?: CarouselPropsWithoutChildren
}

export function ProductShelf({
  products = [],
  limit,
  id,
  variant = 'vertical',
  titleLevel = 3,
  hasQuickView,
  backgroundColor,
  loading,
  title,
  link,
  propsCarousel,
}: ProductShelfProps) {
  // Filter by inStock products and limit that if the "limit" prop exist
  const limitedProductsInStock: ProductsQueryResponse['node'][] =
    useMemo(() => {
      const validatedProducts = []

      for (const product of products) {
        if (verifyProductInStock(product)) {
          validatedProducts.push(product)
        }

        if (limit && validatedProducts.length >= limit) {
          break
        }
      }

      return validatedProducts
    }, [products])

  const { getAnotherScPrices } = useAnotherScPrices(limitedProductsInStock)
  const { viewItemListEvent, genericTaggingEvent } = useAnalyticsEvents()
  const { width } = useWindowDimensions()

  const carouselFinalProps: CarouselPropsWithoutChildren = {
    ...carouselProps(width ?? 0),
    ...propsCarousel,
  }

  const handleViewItemList = () => {
    viewItemListEvent(limitedProductsInStock, id)
  }

  const handleSeeAll = () => {
    genericTaggingEvent('see_all', { name: id })
  }

  // SSR
  if (!width) {
    return (
      <section
        data-shelf-card-variant={variant}
        className="div-content-shelf-product grid-content"
      >
        <Skeleton height={500} backgroundColor={backgroundColor} />
      </section>
    )
  }

  if (loading) {
    return (
      <section
        data-shelf-card-variant={variant}
        className="div-content-shelf-product grid-content"
      >
        <ProductShelfSkeleton
          backgroundColor={backgroundColor}
          variant={variant}
          propsCarousel={carouselFinalProps}
        />
      </section>
    )
  }

  if (limitedProductsInStock.length <= 0) {
    return null
  }

  return (
    <div data-shelf-card-variant={variant} className="grid-content">
      <div className="carousel-product-header">
        <DynamicTitle
          className="carousel-product-header-title"
          titleLevel={titleLevel - 1}
          title={title}
        />

        {link && (
          <Link to={link} onClick={handleSeeAll} data-store-link>
            Ver Tudo
          </Link>
        )}
      </div>

      <WhenVisible
        id={id}
        trigger={handleViewItemList}
        singleActivation
        className="div-content-shelf-product"
      >
        <Carousel id={id} {...carouselFinalProps}>
          {limitedProductsInStock.map((product) => (
            <div
              key={product.id}
              className={
                variant === 'horizontal'
                  ? 'horizontal-product-card-wrapper'
                  : undefined
              }
            >
              <ProductCard
                product={product}
                location={id}
                titleLevel={titleLevel}
                hasQuickView={hasQuickView}
                variant={variant}
                {...getAnotherScPrices(product.sku)}
              />
            </div>
          ))}
        </Carousel>
      </WhenVisible>
    </div>
  )
}
